import {useState, useContext, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import Loader from '../components/loader.js'
import Nav from "../components/Nav.js";
import HeaderBackground from '../components/headerbackground.js'
import PasswordStrengthChecker from '../components/PasswordStrengthChecker.js';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from "../context/GlobalContext.js";

export default function Profile() {

    const [loading, isLoading] = useState(true)
    const [reload, setReload] = useState(false);
    const [passwordChange, SetPasswordChange] = useState({"currentpassword": "", "password": "", "password_confirmation": ""});
    const [contactdetails, setContactDetails] = useState();
    const [profile, setProfile] = useState(null);
    const {theme, updateTheme, themeCSS} = useContext(GlobalContext)
    const [deletePassword, setDeletePassword] = useState();
    const [passwordSecure, setPasswordSecure] = useState(false);
    const [newContactModal, setNewContactModal] = useState(false);
    const [newContactMethod, setNewContactMethod] = useState({});
    const [currentEmail, setCurrentEmail] = useState();
    const [newEmail, setNewEmail] = useState();
    const [changeEmailPassword, setChangeEmailPassword] = useState();
    const [changeEmail2FACode, setChangeEmail2FACode] = useState();
    const [loadingspinner, setLoadingSpinner] = useState(false);
    const [twoFAModal, setTwoFAModal] = useState(false);
    
    const [methods, setMethods] = useState();


    let updatedContactDetail = [];

    async function GetAPIData() {
        setContactDetails();
        const ProfileDetailsResponse = await getAPI('userdetails');
        const ContactDetailsResponse = await getAPI('contactdetail');
        const ContactMethodMappings = await getAPI('getcontactmethodmappings');
        setProfile(ProfileDetailsResponse.data)
        setContactDetails(ContactDetailsResponse.data)
        setMethods(ContactMethodMappings.data);
        setCurrentEmail(ProfileDetailsResponse.data.email)
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        isLoading(false);

        const primaryContactMethodCheckBox = document.getElementById('primaryInputMethod');
        if (primaryContactMethodCheckBox) {
            primaryContactMethodCheckBox.checked = true;
        }
    }

    async function createContact() {
        isLoading(true)
        if(!newContactMethod.methodid){
            toast.error('Please select a contact method') 
            isLoading(false);
            return;
        };
        if(!newContactMethod.reference){
            toast.error('Please enter your contact information') 
            isLoading(false);
            return;
        };
        const body = {id: newContactMethod.methodid, reference: newContactMethod.reference}
        const NewContactMethodResponse = await postAPI("createnewcontactdetail", body);
        if (NewContactMethodResponse.response.status == 200) {
            GetAPIData();
            toast.success('New contact method added successfully.');
            setNewContactModal(false)
            isLoading(false);
        }else{
            toast.error('Enter a valid phone number or email address.');
            isLoading(false);
            return;
        }
        isLoading(false);
    }

    function handleReferenceChange(event) {
        setNewContactMethod({...newContactMethod, 'reference': event.target.value});
    }

    function handleMethodTypeChange(event){
        setNewContactMethod({...newContactMethod, 'methodid': event.target.value});
    }

    function handlePasswordChange(event) {
        let statename = event.target.id;
        let value = event.target.value;
        SetPasswordChange({...passwordChange, [statename]: value});
    }

    function codeChange(event) {
        setChangeEmail2FACode(event.target.value);
    }

    function cancel2FA() {
        setTwoFAModal(false) 
        setLoadingSpinner(false)
    }

    async function changePassword(){
        if(!passwordSecure){
            toast.error("Your password does not meet the minimum requirements");
            return
        }
        if (passwordChange.password == passwordChange.password_confirmation) {
            const ChangePasswordResponse = await postAPI("changepassword", passwordChange);
            if (ChangePasswordResponse.response.status == 200) {
                toast.success('Password changed, you will now be logged out.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = '/login'
                }, 5000);
            } else {
                toast.error('Your current password is invalid please try again.');
            }
        } else {
            toast.error('Your new passwords do not match.');
        }
    }

    async function updateContactDetailViaAPI(id, reference, isPrimaryCommunicationMethod){
        isLoading(true);
        let isPrimary = isPrimaryCommunicationMethod ? isPrimaryCommunicationMethod : false;
        const body = {id: id, reference: reference, isPrimary: isPrimary}
        const UpdateContactDetailsResponse = await postAPI("contactdetail/update", body);
        if (UpdateContactDetailsResponse.response.status == 200) {
            const ContactDetailsResponse = await getAPI('contactdetail');
            setContactDetails(ContactDetailsResponse.data)
            toast.success('Your contact details have been updated.');
            isLoading(false);
        }else{
            toast.error('Enter a valid phone number or email address.');
            isLoading(false)
            return
        }
    }

    function handleChange(event) {
        let filtered = updatedContactDetail.filter(account => {
            return account.id == event.target.id
        })
        if (!filtered[0]) {
            //If the record is not in the array then push it
            updatedContactDetail.push({'id': event.target.id, 'reference': event.target.value})
        } else {
            //If it already exists in the array - update the reference
            let objIndex = updatedContactDetail.findIndex((obj => obj.id == event.target.id));
            updatedContactDetail[objIndex].reference = event.target.value
        }
    }

    function updateContactDetail(){
        updatedContactDetail.map(function(contactdetail, index) {
            updateContactDetailViaAPI(contactdetail.id, contactdetail.reference)
        })
    }

    function deletePasswordChange(event) {
        setDeletePassword(event.target.value);
    }

    async function deleteAccount(event) {
        const body = {password: deletePassword}
        const DeleteAccountResponse = await postAPI("deletetenantaccount", body);
        if (DeleteAccountResponse.response.status == 200) {
            toast.success('Your account has been deleted. You will now be logged out');
            setTimeout(() => {
                localStorage.clear();
                window.location.href = '/login'
            }, 5000);
        } else {
            toast.error('Your password is invalid.');
        }
    }

    async function setPrimaryCommunicationMethod(event){
        isLoading(true);
        const index = event.target.selectedIndex;
        const element = event.target.childNodes[index]
        const id =  element.getAttribute('id'); 
        const reference =  element.getAttribute('reference'); 
        if(id && reference) await updateContactDetailViaAPI(id, reference, true)
        isLoading(false);
    }
  
    async function requestLoginEmailChangeCode(){
        const body = {email: newEmail, password: changeEmailPassword}
        const RequestEmailChangeCode = await postAPI("getemailchangecode", body);
        const statusCode = RequestEmailChangeCode.response.status;
        if(statusCode == 200) {
            setTwoFAModal(true);
        } else if(statusCode == 400){
            toast.error('Given email is the same as current email');
        } else if(statusCode == 403){
            toast.error('Invalid Password');
        } else if(statusCode == 422){
            toast.error('Please enter a vaild email address and your current password');
        } else {
            toast.error('An error occurred, please try again');
        }
    }

    async function completeLoginEmailChange(){
        const body = {email: newEmail, password: changeEmailPassword, token: changeEmail2FACode}
        const ConfirmLoginEmailChange = await postAPI("completeemailchange", body);
        const statusCode = ConfirmLoginEmailChange.response.status;
        if(statusCode == 200) {
            setCurrentEmail(newEmail);
            toast.success('Your email address has been updated successfully');
            setTwoFAModal(false)
        } else if (statusCode == 403){
            toast.error('Incorrect verification code');
        } else {
            toast.error('An error occurred, please try again');
        }
    }

    function HeaderOverlay() {
        return (
            <>
                <div className="w-11/12 lg:w-8/12 border stats absolute top-46 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div className={"py-8 grid grid-cols-1 lg:grid-cols-12 stat place-items-center place-content-ceter " + (themeCSS.divCSS)}>
                        <div className="col-span-12 lg:col-span-12 place-items-center place-content-center">
                            <div className="text-xl">
                                <strong>Your Profile</strong>
                            </div> 
                        </div> 
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        GetAPIData();
    }, [reload]);

    return (
        <> 
            {loading && (
                <div className="">
                    <Loader width="100%" height="100%"/>
                </div>
            )}
            <div className={"min-h-screen " + (themeCSS.divCSS)}>
                <Nav />
                <HeaderBackground />
                <HeaderOverlay/>
                <div className={(themeCSS.divCSS)}>  

                    <div className='mt-20 justify-center lg:mx-[240px] mx-6 mb-8'>
                        <div className={"rounded-xl border overflow-auto p-4 " + (themeCSS.divCSS)}>
                            <div className="flex flex-col items-center space-y-2">
                                {contactdetails && (
                                <div className="w-full pb-2">
                                    <h1 className={"font-bold text-xl text-gray-700 w-6/6 " + (themeCSS.divCSS)}>
                                        Contact Details
                                    </h1>
                                    <p className={"text-sm text-gray-500 text-left " + (themeCSS.divCSS)}>
                                        Its important to keep your contact details up to date so we can contact you when needed.
                                    </p>
                                    <legend className="text-sm font-semibold leading-6 mt-6">Primary Contact Method</legend>
                                    <p className="mt-1 text-sm leading-6">Select your prefered primary contact method below. We will try and contact you via this method whenever possible.</p>
                                    <fieldset>
                                        <div className="mt-2 space-y-2">
                                            {contactdetails && (
                                                contactdetails.map(function(contactdetail, index){
                                                    return (
                                                    <>                                               
                                                        {contactdetail.isprimary ? (
                                                            <div className="flex items-center gap-x-3">
                                                                <input
                                                                    id={'primaryInputMethod'}
                                                                    name="primary-communcation-method"
                                                                    type="radio"
                                                                    className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                                                />
                                                                <label htmlFor="primary-communcation-method" className="block text-sm font-medium leading-6">
                                                                {contactdetail.description}: {contactdetail.reference}
                                                                </label>
                                                            </div>
                                                        ):(
                                                            <div className="flex items-center gap-x-3">
                                                                <input
                                                                    id={index}
                                                                    name="primary-communcation-method"
                                                                    type="radio"
                                                                    onClick={()=>{updateContactDetailViaAPI(contactdetail.id, contactdetail.reference, true)}}
                                                                    className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                                                />
                                                                <label htmlFor="primary-communcation-method" className="block text-sm font-medium leading-6">
                                                                {contactdetail.description}: {contactdetail.reference}
                                                                </label>
                                                            </div>
                                                        )}
                                                    </>
                                                )})
                                            )}  
                                        </div>
                                    </fieldset>
                                    
                                </div>
                                )}
                                <div className="w-full pb-2">
                                    <legend className="text-sm font-semibold leading-6 mt-4">Contact Information</legend>
                                    <p className="mt-1 text-sm leading-6">You can update the details below by simpily entering the new details. Alternatively, you can add a new contact method.</p>
                                </div>
                                <div className="grid grid-cols-12 w-full">
                                    {contactdetails && (
                                        contactdetails.map(function(contactdetail, index){
                                            return (
                                            <>
                                                <div className="col-span-full mb-2">
                                                <label className={"block text-sm font-medium leading-6 text-left text-gray-900 "  + (themeCSS.divCSS)}>
                                                    {contactdetail.description}
                                                </label>
                                                <div className="mt-">
                                                    <input
                                                    type="text" id={contactdetail.id} onChange={handleChange} placeholder={contactdetail.reference}
                                                    className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 "  + (themeCSS.divCSS)}
                                                    />
                                                </div>
                                                </div>
                                            </>
                                        )})
                                    )}  
                                </div>
                            </div>
                            <div className="flex flex-col items-end">
                                <div className="flex flex-row gap-2">
                                    <button  onClick={() => {setNewContactModal(true)}} className="mt-4 btn btn-natural capitalize text-sm grid rounded-lg text-white text-md">
                                        <p className="col-span-12">Add new method</p>
                                    </button>
                                    <button  onClick={() => {updateContactDetail()}} className="mt-4 btn btn-natural capitalize text-sm grid rounded-lg text-white text-md">
                                        <p className="col-span-12">Save Changes</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2 justify-center lg:mx-[240px] mx-6 mb-8'>
                        <div className={"rounded-xl border overflow-auto p-4 " + (themeCSS.divCSS)}>
                            <div className="flex flex-col items-start space-y-2">
                                <div className="w-full pb-0">
                                    <h1 className={"font-bold text-xl text-gray-700 w-6/6 " + (themeCSS.divCSS)}>
                                        Change your account email address 
                                    </h1>
                                    <div className={"text-sm text-gray-500 text-left mt-2 " + (themeCSS.divCSS)}>
                                        Please note, your account email address is not linked to your contact preferences.
                                    </div>
                                    {currentEmail && (
                                    <div className={"text-md text-gray-700 text-left mt-2 " + (themeCSS.divCSS)}>
                                        Your current email address is: <strong>{currentEmail}</strong>
                                    </div> 
                                )}
                                </div>
                                <div className="grid grid-cols-1 w-full">
                                    <input type="email" id="newemail" onChange={(event)=>{setNewEmail(event.target.value)}} placeholder="Enter your new email address" 
                                    className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 "  + (themeCSS.divCSS)}
                                    />
                                </div>
                                <div className="grid grid-cols-1 w-full">
                                    <input type="password" id="changeemailpassword" onChange={(event)=>{setChangeEmailPassword(event.target.value)}} placeholder="Enter your password" 
                                    className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 "  + (themeCSS.divCSS)}
                                    />
                                </div>          
                            </div>
                            <div className="flex flex-col items-end">
                            <button  onClick={() => {requestLoginEmailChangeCode()}} className="mt-4 btn btn-natural capitalize text-sm grid rounded-lg text-white text-md">
                                <p className="col-span-12">Change account email</p>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2 justify-center lg:mx-[240px] mx-6 mb-8'>
                        <div className={"rounded-xl border overflow-auto p-4 " + (themeCSS.divCSS)}>
                            <div className="flex flex-col items-start space-y-2">
                                <h1 className={"font-bold text-xl text-gray-700 w-6/6 text-center " + (themeCSS.divCSS)}>
                                    Change your password 
                                </h1>
                                <div className="grid grid-cols-1 w-full">
                                    <input type="password" id="currentpassword" onChange={handlePasswordChange} placeholder="Enter your current password" 
                                    className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 "  + (themeCSS.divCSS)}
                                    />       
                                </div>
                                <div className="grid grid-cols-1 w-full">
                                    <input type="password" id="password" onChange={handlePasswordChange} placeholder="Enter your new password" 
                                    className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 "  + (themeCSS.divCSS)}
                                    />      
                                </div>
                                <PasswordStrengthChecker password={passwordChange.password} passwordSecure={setPasswordSecure}/>

                                <div className="grid grid-cols-1 w-full">
                                    <input type="password" id="password_confirmation" onChange={handlePasswordChange} placeholder="Re-enter your new password" 
                                    className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 "  + (themeCSS.divCSS)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col items-end">
                            <button  onClick={() => {changePassword()}} className="mt-4 btn btn-natural capitalize text-sm grid rounded-lg text-white text-md">
                                <p className="col-span-12">Change password</p>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2 justify-center lg:mx-[240px] mx-6 mb-8'>
                        <div className={"rounded-xl border overflow-auto p-4 " + (themeCSS.divCSS)}>
                            <div className="flex flex-col items-start space-y-2">
                                <h1 className={"font-bold text-xl text-gray-700 w-6/6 text-left " + (themeCSS.divCSS)}>
                                    Delete your account 
                                    </h1>
                                <p className={"text-md text-gray-500 text-start w-6/6 " + (themeCSS.divCSS)}>
                                    If you no longer wish to manage your account via our portal, you can delete your account using the button below. 
                                </p>
                                <p className={"text-md text-gray-500 text-start w-6/6 " + (themeCSS.divCSS)}>
                                    <strong>IMPORTANT: Deleting your account is irreversible. You can re-register at any time.</strong>
                                </p>
                                <div className="grid grid-cols-1 w-full">
                                    <input type="text" id="password" onChange={deletePasswordChange} placeholder="Enter your current password" 
                                    className={"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 "  + (themeCSS.divCSS)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col items-end">
                            <button  onClick={() => {deleteAccount()}} className="mt-4 btn btn-natural capitalize text-sm grid rounded-lg text-white border-red-500 bg-red-500 text-md">
                                <p className="col-span-12">Delete account</p>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={newContactModal}
                    onRequestClose={() => {setNewContactModal(false)}}
                    className="my-20 mx-2 pb-12 md:mx-40 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
                    contentLabel="Profile">
                        <div>
                            <div className="bg-white rounded-xl border overflow-auto p-10">
                                <div className="flex flex-col items-center space-y-2">
                                    <h1 className="font-bold text-xl text-gray-700 w-4/6 text-center">Create a new contact method</h1>
                                    <div className="grid grid-cols-1 md:grid-cols-1 w-full gap-2 space-y-2 md:space-y-0">
                                        <select required className="border-2 border-gray-300 rounded w-full h-10 px-2 text-gray-600" onChange={handleMethodTypeChange}>
                                            <option value="">Please select a Method Type</option>
                                            {methods && (
                                                methods.map(function(method, index){
                                                    return (
                                                        <option key={index} value={method.id}>{method.name}</option>
                                                    )}
                                                )
                                            )}
                                        </select>
                                        <div>
                                            <input required placeholder={'Enter your contact information here'} type="text" name="methodname" onChange={handleReferenceChange} className="border-2 border-gray-400 rounded-lg w-full h-12 px-4 text-gray-800"/>
                                        
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div className="flex flex-col items-center space-y-4">
                                        <button className="btn btn-natural capitalize border-0 bg-gray-300 text-white rounded-md font-semibold px-4 py-3 w-full mt-4" onClick={() => {setNewContactModal(false)}}>Cancel</button>
                                    </div>
                                    <div className="flex flex-col items-center space-y-4">
                                        <button className="btn btn-natural capitalize text-white rounded-md font-semibold px-4 py-3 w-full mt-4" onClick={() => {createContact()}}>Save</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                </Modal>
                <Modal
                    isOpen={twoFAModal}
                    onRequestClose={() => {setTwoFAModal(false)}}
                    className="overflow-y-hidden overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center"
                    contentLabel="DeleteModal">
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-30 overflow-y-auto h-full w-full">
                            <div className="bg-white rounded-lg w-96 relative top-60 mx-auto border">
                                <div className="text-center pt-2 text-xl font-bold text-gray-600">Change Email Verification</div>
                                <div className="text-center pt-2 text-sm text-gray-600 px-4">We have just sent a code to your new email address, please enter it below.</div>
                                <div className="rounded-lg flex">
                                    <input onChange={codeChange} required className="h-20 border-4 border-gray-300 w-full m-4 rounded-xl text-center text-xl text-gray-700 focus:border-gray-300" placeholder='Enter your code here'></input>
                                </div>
                                <div className="px-2 pb-2 flex space-x-4">
                                <a onClick={() => {cancel2FA()}} className="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm">Cancel</a>
                                {loadingspinner ?
                                    <a onClick={() => {completeLoginEmailChange(this)}} className="w-1/2 px-4 py-2 text-center text-white bg-gray-600 rounded-lg hover:text-white font-bold text-sm">
                                    <div role="status" className="flex justify-center">
                                            <svg aria-hidden="true" className="mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                            </svg>
                                        </div>
                                    </a>
                                :
                                <a onClick={() => {completeLoginEmailChange(this)}} className="w-1/2 px-4 py-3 text-center text-white bg-gray-600 rounded-lg hover:text-white font-bold text-sm">Continue</a>
                                }
                                </div>
                            </div>
                        </div>
                </Modal>
            </div> 
        </>
    );
    
}

