import { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import Loader from '../components/loader.js';
import Nav from '../components/Nav.js';
import HeaderBackground from '../components/headerbackground.js';
import getAPI from '../functions/API/getAPI.js';
import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from '../context/GlobalContext.js';
import moment from 'moment';
import { Fragment } from 'react';
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid';
import { useParams } from 'react-router-dom';
import { DocumentIcon } from '@heroicons/react/24/outline';

export default function Documents() {
    const [loading, isLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const { theme, updateTheme, themeCSS } = useContext(GlobalContext);
    const [documents, setDocuments] = useState([]);
    const [loadingDocuments, setLoadingDocuments] = useState({}); // State to track loading for each document

    const { id } = useParams();

    async function GetDocuments() {
        isLoading(true);
        const DocumentResponse = await getAPI('documents/' + id);
        if (DocumentResponse.response.ok && DocumentResponse.data.length > 0) {
            setDocuments(DocumentResponse.data);
        }
        isLoading(false);
    }

    async function downloadDocument(docID) {
        setLoadingDocuments(prevState => ({ ...prevState, [docID]: true })); // Set loading state for the clicked document
        let chunk = 0;
        let data = [];
        let extension;
        let fileName;
        let maxChunks;

        const response = await getAPI(`documents/download/${docID}/${chunk}`, false, true);
        if (!response.response.ok) {
            toast.error('Error downloading document');
            GetDocuments();
            return
        }

        maxChunks = parseInt(response.response.headers.get('X-Chunks'), 10);
        extension = response.response.headers.get('X-Extension');
        fileName = response.response.headers.get('X-File-Name');

        const chunkData = await response.response.arrayBuffer();
        data.push(chunkData);
        chunk++;

        try {
            // Continue requesting until all chunks are retrieved
            while (chunk < maxChunks) {
                const response = await getAPI(`documents/download/${docID}/${chunk}`, false, true);

                if (!response.response.ok) {
                    toast.error('Error downloading document');
                    GetDocuments();
                }

                const chunkData = await response.response.arrayBuffer();
                data.push(chunkData);
                chunk++;
            }

            const combinedData = new Uint8Array(data.reduce((acc, val) => acc.concat(Array.from(new Uint8Array(val))), []));
            const blob = new Blob([combinedData]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.${extension}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingDocuments(prevState => ({ ...prevState, [docID]: false })); // Reset loading state for the clicked document
        }
    }

    function HeaderOverlay() {
        return (
            <>
                <div className="w-11/12 lg:w-8/12 rounded-md border stats absolute top-46 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div className={'py-4 grid grid-cols-1 lg:grid-cols-12 stat place-items-center place-content-ceter ' + themeCSS.divCSS}>
                        <div className="col-span-12 lg:col-span-12 place-items-center place-content-center">
                            <div className="text-xl">
                                <strong>Your Documents</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function AppointmentTable() {
        return (
            <div className="w-11/12 lg:w-8/12 mx-auto mt-10">
                {documents.length > 0 ? (
                    documents.map((document, index) => (
                        <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200 mb-2" key={index}>
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div className="flex w-0 flex-1 items-center">
                                    <DocumentIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">{document.name}</span>
                                        <span className="flex-shrink-0 text-gray-400">{(document.byte_size / (1024 * 1024)).toFixed(2)} MB</span>
                                        <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                                            .{document.file_extension}
                                        </span>
                                    </div>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                    {loadingDocuments[document.id] ? (
                                        <div className="flex justify-center">
                                            <svg aria-hidden="true" className="mr-2 w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-800" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                        </div>
                                    ) : (
                                        <button onClick={() => downloadDocument(document.id)} className="font-medium text-blue-600 hover:text-blue-500">
                                            Download
                                        </button>
                                    )}
                                </div>
                            </li>
                        </ul>
                    ))
                ) : (
                    <div className="w-full flex justify-center items-center">
                        <div className="text-center">
                            <p className="text-md">No documents found</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    useEffect(() => {
        GetDocuments();
        }, [reload]);
    
    return (
        <>
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}
        
            <div className={"min-h-screen " + (themeCSS.divCSS)}>
                <Nav />
                <HeaderBackground />
                <HeaderOverlay />
                <AppointmentTable />
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div> 
        </>
    );
    
}

